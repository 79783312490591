import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png'

import Stage from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Stage.jpg'
import Overhauling from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Overhauling.jpg'
import Servising from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Servising.jpg'
import Reconditioning from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Reconditioning.jpg'
import Optimisation from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Optimisation.jpg'
import Retrofitting from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Retrofitting.jpg'
import Inspection from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Inspection.jpg'
import HoseManagement from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/HoseManagement.jpg'
import HFOffers from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/HFOffers.jpg'
import AllAboutOil from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/AllAboutOil.jpg'
import Availability from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/Availability.jpg'
import YouCanCount from '../../../assets/Services/RepairAndOverHaul/RepairAndOverhaul/YouCanCount.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import HFTab from '../../../Components/HfTabs/HfTabs';


const RepairAndOverhaul = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    const featuresData = [
        {
            title: "Professional service",
            description: "All work is carried out by highly competent technicians with years of experience",
            image: imgMark
        },
        {
            title: "Minimum standstill times",
            description: "Rapid order processing and optional express service for time-critical repairs",
            image: imgMark
        },
        {
            title: "Proven quality",
            description: "Performance of various tests and preparation of detailed test reports if required",
            image: imgMark
        },
        {
            title: "Recondition instead of replacement",
            description: "Particularly in the case of expensive components, reconditioning is often an economically better solution",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Optimisation of hydraulic systems",
            description: "Increased wear on hydraulic components is a sign that something is wrong in the system. Systematically performed fault analyses show precisely where the weak spots are hiding. After HANSA‑FLEX Industrial Service and Fluid Service specialists have examined the components and analysed the fluid, they use the findings to develop specific recommendations for optimisation. With the optimisation work completed, the machines operate more reliably and their efficiency sustainably improves.",
            buttonName: "READ MORE",
            buttonLink: "/modernisation-and-optimisation",
            image: Optimisation
        },
        {
            title: "Retrofitting and renovation",
            description: "As a system partner, we support you extensively with all the tasks required for the renovation of your machines and systems:",
            listItems: [
                "Economic analysis of renovation projects",
                "Planning and advice about renovation measures",
                "Performance of all work related to fluid technology"
            ],
            image: Retrofitting,
            buttonName: 'READ MORE',
            buttonLink: '/modernisation-and-optimisation'
        },
        {
            title: "Inspection of hydraulic operating equipment",
            description: "The trained experts from HANSA‑FLEX are officially authorised persons for inspections of hydraulic equipment and material in accordance with the currently applicable regulations. They carry out the inspections and prepare the inspection reports.",
            image: Inspection,
            buttonName: 'READ MORE',
            buttonLink: '/inspection'
        },
        {
            title: "360° hose management from HANSA‑FLEX",
            description: "Professional management of your hydraulic hose lines ensures you benefit from more efficient servicing and maintenance of your machines and systems.",
            listItems: [
                "Clear hose line labelling with X-CODE",
                "Easy-to-use X-CODE Manager hose management software",
                "Various services packages for hose management"
            ],
            image: HoseManagement,
            buttonName: 'READ MORE',
            buttonLink: '/hose-management'
        },
    ];

    const tabTitles = [
        { title: "Optimisation", eventKey: "Optimisation" },
        { title: "Retrofitting", eventKey: "Retrofitting" },
        { title: "Inspections", eventKey: "Inspections" },
        { title: "Hose management", eventKey: "Hose management" },
    ];

    const data1 = {
        image: HFOffers,
        listItems: [
            "Damage detection and fault analysis",
            "Prepare a cost estimate",
            "Perform all the work associated with the repair",
            "Dismantle, pick-up, return and reinstall",
            "Environmentally compatible disposal of defective components",
            "Advice about machine and plant optimisation"
        ]
    }



    const isMobile = window.innerWidth <= 768;

    return (
        <div>
            <CenterStage
                imageSrc={Stage}
                title={"REPAIR AND SERVICING OF HYDRAULIC SYSTEMS AND COMPONENTS"}
                paragraph={"Hydraulic systems are continuously under load and therefore wear will eventually occur on their components. If this is not detected in time, it can lead to complete failure of the hydraulic system. Your machines and systems are back running again in no time at all with professional repairs and maintenance from HANSA‑FLEX. We also support you with system analysis to detect possible damage in good time and prevent unplanned downtime."}
            />
            <div>
                <SectionHeader
                    title={"PROFESSIONAL REPAIRS BY HANSA‑FLEX"}
                    subtitle={"With HANSA‑FLEX you benefit from rapid and professional repairs performed by experts experienced in fluid technology."}
                />
            </div>
            <div>
                <Container className='custom-container'>
                    <Row>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={Overhauling} learnMoreLink={"/hydraulic-cyclinder-repair"}
                            title="Overhauling defective hydraulic cylinders"
                            desc={"The HANSA‑FLEX specialists repair damaged hydraulic cylinders of all sizes, manufacturers and types."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Servising} learnMoreLink={"/pressure-accumulators-and-associated-systems"}
                            title="Servicing and repair of pressure accumulators"
                            desc={"The fluid experts from HANSA‑FLEX are officially authorised persons for pressure equipment and support you with all the required maintenance tasks."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Reconditioning} learnMoreLink={"/#contact-us-section"}
                            title="Reconditioning of pumps, motors and power units"
                            desc={"The HANSA‑FLEX cylinder repair centre not only reconditions hydraulic cylinders but also repairs other hydraulic components quickly to ensure minimum downtime."} /></Col>
                    </Row>
                </Container>
            </div>

            <SectionHeader
                title={"FLUID MANAGEMENT PREVENTS OIL-RELATED DAMAGE"}
                subtitle={"Contaminants in the fluid can have serious consequences. Meticulous oil care is therefore key to ensuring fault-free operation of hydraulic systems. HANSA‑FLEX fluid experts support you with everything from oil analysis to cleaning and continuous monitoring."}
            />

            <div>
                <SectionHeader
                    title={"HANSA‑FLEX REPAIR SERVICE"}
                    subtitle={"You benefit in many ways by using HANSA‑FLEX Repair Service."}
                />
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"REPAIR SERVICES"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA‑FLEX OFFERS AN ALL-ROUND SERVICE FROM A SINGLE PROVIDER"}
                    subtitle={"We offer you a comprehensive service for everything to do with the repair and overhaul of your hydraulic components – and all from a single provider."}
                />
                <ImageText
                    data={data1}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SOMETHING THAT MAY ALSO INTEREST YOU"}
                />
            </div>

            <div>
                <Container className='custom-container'>
                    <Row>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={AllAboutOil} learnMoreLink={"/"}
                            title="All about oil : Fluid Service"
                            desc={"HANSA‑FLEX Fluid Service carries out oil analyses directly on site, performs oil cleaning or the complete replacement of the fluid and advises on all aspects of fluid...."}
                        /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Availability} learnMoreLink={"/mobile-rapid-hydraulic-service"}
                            title="24/7 Availability : Rapid Hydraulic Service"
                            desc={"We are there for you in an emergency, all round the clock: our Rapid Hydraulic Service can quickly replace defective components such as hose lines."}
                        /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={YouCanCount} learnMoreLink={"/assembly-and-installation"}
                            title="You can count on us : Industrial Service"
                            desc={"TWe offer you a complete service through our HANSA‑FLEX Industrial Service. The service technicians perform servicing, maintenance and installation of hydraulic hose lines and...."}
                        /></Col>

                    </Row>
                </Container>

            </div>



        </div>








    )
}

export default RepairAndOverhaul
