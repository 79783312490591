import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/FluidManagement/OilAnalysis/Stage.jpg'
import Determineinfo from '../../../assets/Services/FluidManagement/OilAnalysis/Determineinfo.jpg'
import SystematicDamage from '../../../assets/Services/FluidManagement/OilAnalysis/SystematicDamage.jpg'
import ComprehensiveManagement from '../../../assets/Services/FluidManagement/OilAnalysis/ComprehensiveManagement.jpg'
import Particles from '../../../assets/Services/FluidManagement/OilAnalysis/Particles.jpg'
import Water from '../../../assets/Services/FluidManagement/OilAnalysis/Water.jpg'
import Air from '../../../assets/Services/FluidManagement/OilAnalysis/Air.jpg'
import Temp from '../../../assets/Services/FluidManagement/OilAnalysis/Temp.jpg'
import Mixing from '../../../assets/Services/FluidManagement/OilAnalysis/Mixing.jpg'
import KeepAnEye from '../../../assets/Services/FluidManagement/OilAnalysis/KeepAnEye.jpg'
import EfficientOil from '../../../assets/Services/FluidManagement/OilAnalysis/EfficientOil.jpg'

const OilAnalysis = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //ImageText Data
    const data1 = {
        image: Determineinfo,
        title: 'Determine all the information about the oil',
        description: 'A check-up of your hydraulic system including a professional oil analysis provides all the important information about your fluid, such as contamination, mixing and aging. The cleanliness level of the oil is also measured in accordance with ISO 4406. The oil analysis is performed by an accredited and certified independent laboratory. The HANSA‑FLEX fluid specialists interpret the results to produce specific recommendations. In this way, you can rectify faults before they fully develop and cause damage.',
    }

    const data2 = {
        image: SystematicDamage,
        title: 'Systematic damage detection',
        description: 'When damage occurs, you have to act quickly. Experienced HANSA‑FLEX fluid and hydraulics specialists search for the causes directly on-site and immediately carry out a professional oil analysis. The results form the basis for detecting damage to components such as pumps, cylinders, hose lines and filters. Then the experts set about the specific measures required to bring your system back into working order. We can also plan and manage other work to keep your system operating smoothly over the long term, such as installing filters or flushing the hydraulics. We also support you in the implementation of preventative maintenance measures.',
    }

    const data3 = {
        image: ComprehensiveManagement,
        title: 'Comprehensive fluid management',
        description: 'Your oil analysis provides the foundation for optimizing your system. It shows up any possible weak points and helps you to rectify them in good time. In addition to performing various measures such as cleaning or drying the hydraulic oil, HANSA‑FLEX Fluid Service can also implement other processes as part of fluid management. From defining the schedule of oil care processes to deciding on the servicing intervals, from monitoring the results of the measures right up to employee training: the experienced fluid and hydraulic specialists at HANSA‑FLEX are available at any time to answer questions and provide information about fluid management.',
    }

    const data4 = {
        image: KeepAnEye,
        listItems: ["Present state analysis and basic planning", "Definition of limiting values to meet the hydraulic system requirements", "Selection and installation of oil condition sensors", "Configuration and setting up of data transmission paths", "Evaluation and interpretation of measured values", "Manufacture and installation of alarm devices etc."]
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Perfect analysis",
            description: "Oil analyses in the laboratory or on-site give definitive information about the condition of the oil and the machine.",
            image: imgMark
        },
        {
            title: "Reliable fault detection",
            description: "Faults such as oil mixing, contamination or water in the oil are detected immediately.",
            image: imgMark
        },
        {
            title: "Oil change timing",
            description: "The optimum oil change interval is determined individually for each machine or plant.",
            image: imgMark
        },
        {
            title: "Guaranteed quality",
            description: "Quality checks after oil changes and performance of warranty services.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Contamination by solids",
            description: "Contamination of hydraulic oil by solid materials such as dust or particles of metal or seals is one of the most frequent causes of damage. The source of the particles could be unfiltered fresh oil, an inadequate filter design, a poorly filled tank, erosion of components or the entry of dirt particles during assembly. The first step in rectifying this problem is to examine the whole system, including the use of particle counters on-site if required. By doing this, you can be absolutely sure of the cause of the damage and take precisely the measures required to prevent the entry of dirt, such as installing filter systems.",
            image: Particles
        },
        {
            title: "Water contamination",
            description: "Contamination of hydraulic oil with water causes corrosion, cavitation, sludge formation or premature aging of the oil. Water contamination can be caused by condensation, oil-water coolers, cleaning with steam jets, severe temperature fluctuations, spray water entry or defective cylinder seals. To prevent water from contaminating hydraulic oil, HANSA‑FLEX Fluid Service first performs an intensive inspection of the entire hydraulic system looking for possible causes. Then the experts draw up a list of suitable measures, such as the use of desiccant breathers or vacuum dewatering systems and, if you wish, implement them.",
            image: Water
        },
        {
            title: "Undissolved air",
            description: "Too little oil in the tank, leaking suction lines to the pump or inadequate bleeding: too much undissolved air in hydraulic oil can lead to component damage. Air-enriched hydraulic oil is prone to premature aging. There is also the risk of bubbles in the oil-gas mixture imploding (cavitation) due to pressure and temperature peaks or exploding under too much compression (diesel effect). From the design of the oil tank to the selection of suitable fluid right up to checking the effectiveness of the air separation components: HANSA‑FLEX’s experienced fluid and hydraulic experts advise you on how to avoid oxidation, cavitation or micro-diesel effects.",
            image: Air
        },
        {
            title: "High temperatures and shear",
            description: "Excessive operating temperatures reduce the performance of hydraulic oil. The consequences: ineffective additives, accelerated oil aging, acid formation in the oil and, last but not least, damage to components. Extreme pressure and temperature peaks lead to overstressing of the fluid. Excessive stress breaks up the chains of molecules in the oil, which adversely affects its flow characteristics. This can also lead to additives losing their effect, shorter oil change intervals and higher wear on components. Monitoring temperature and pressure, either as part of the oil analysis or using sensors, can be worthwhile in preventing the damaging effects of high temperatures.",
            image: Temp
        },
        {
            title: "Oil mixing",
            description: "Mixing different hydraulic oils often leads to a worsening of their physical properties. Mixing often happens unintentionally when topping up, removing or reattaching equipment or changing the oil. Even combinations of oil with the same classification can result in elastomeric components and hydraulic hose lines swelling, foam formation, less effective air separation or sludge in the oil. Mixing hydraulic oils also results in loss of all rights under the machine manufacturer’s warranty. No combination of oils can be guaranteed to be 100 % problem-free. Therefore, you should always avoid mixing different oils.",
            image: Mixing
        },
    ];

    const tabTitles = [
        { title: "Particles", eventKey: "Particles" },
        { title: "Water", eventKey: "Water" },
        { title: "Air", eventKey: "Air" },
        { title: "Temperature", eventKey: "Temperature" },
        { title: "Mixing", eventKey: "Mixing" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"INFORMATIVE OIL ANALYSIS"}
                    paragraph={"Contamination, mixing and aging of oil: professional oil analyses allow oil-related problems to be detected at an early stage so that they can be rectified before they lead to wear and machine stoppages. HANSA‑FLEX fluid specialists carry out their tests directly on-site or in the laboratory, make their recommendations and implement the appropriate measures. They can also install oil condition sensors for continuous fluid monitoring."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'SYSTEMATIC OIL ANALYSES'}
                    subtitle={'Take a systematic approach based on comprehensive oil analyses'}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={"PROFESSIONAL OIL ANALYSES FROM EXPERTS"}
                    subtitle={"The HANSA‑FLEX Fluid Service professional oil analysis reveals all the important information your hydraulic oil contains"}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"CONTAMINATED FLUID"}
                    subtitle={"These five factors are damaging to hydraulic oil"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={'KEEP AN EYE ON YOUR OIL WITH CONDITION MONITORING'}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"WHAT EVERYONE SHOULD KNOW ABOUT FLUID MANAGEMENT"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={EfficientOil} learnMoreLink={"/oil-filtering-and-cleaning"}
                                title="Efficient oil filtering"
                                desc={"Regular oil filtration ensures your production processes run smoothly and helps avoid damage to equipment, systems and machines."} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </div>
    )
}

export default OilAnalysis