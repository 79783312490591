import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import stage from '../../assets/units/sa/stage2.jpg'
import { MdEmail, MdFax, MdLocationOn, MdOutlineEmail, MdPhone } from 'react-icons/md';
import { FaPhoneVolume } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import NumberGrid from '../../Components/NumberGrid/NumberGrid';
import imgSouthAfrica from "../../assets/SouthAfrica.png";
// import imgSA1 from "../../assets/SA1.jpg"
import imgSA1 from "../../assets/GHBWorkshop.jpg"
import imgSA2 from "../../assets/SA2.jpg"
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import ImageText from '../../Components/ImageText/ImageText'
import imgSAFastdelivery from "../../assets/Whoweare/SAFastdelivery.jpg"
import imgSAcustomerssatisfaction from "../../assets/Whoweare/SAcustomerssatisfaction.jpg"

import HansaMap from "../../assets/Whoweare/HansaMap.png"
import imgSATechnicalexpertise from "../../assets/Whoweare/SATechnicalexpertise.jpg"
import imgSAmap from "../../assets/Whoweare/SAmap.gif"
import imgSAquality from "../../assets/Whoweare/SAquality.jpg"
import FullImageText from '../../Components/FullImageText/FullImageText';
import imgBremen from '../../assets/Whoweare/Bremen.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import qualitySticker from '../../assets/Whoweare/QualityLogo.png';
import glob from '../../assets/Whoweare/GlobLogo.png';
import customersatisfaction from "../../assets/Whoweare/customer satisfaction.png";
import BBEE from "../../assets/Whoweare/BBEE.png"
import service from "../../assets/Whoweare/service.png";
import techexpert from "../../assets/Whoweare/techexpert.png"
import exporsalesteam from "../../assets/Whoweare/export sales team.png"
import isoLogo from '../../assets/Whoweare/ISO-Logo.png';
import imgMark from '../../assets/mark.png';
import ShowcaseCarousel from '../../Components/ShowcaseCarousel/ShowcaseCarousel';
import imgservice from '../../assets/Whoweare/service.jpg'
import imgLogisticsImg from '../../assets/Whoweare/LogisticsImg.jpg'


const AboutUsSA = () => {

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 320, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 300, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //hall of fame
    const numbers2 = ['41+', '449+', '401+'];
    const subtitles2 = ['Countries', 'Branches worldwide', 'field service vehicles worldwide'];

    const numbers = ['4', '10', '38000+'];
    const subtitles = ['Branches', 'field Service Vehicles in South Africa', 'Hose assemblies Manufactured in South Africa in 2023'];

    const carouselItems = [
        {
            imgSrc: imgservice,
            title: 'Smooth Orders, Speedy Deliveries',
            description: 'At HANSA-FLEX South Africa, we ensure top-notch customer service and the delivery of high-quality products.'
        },

        {
            imgSrc: imgLogisticsImg,
            title: 'We Make It Happen, We Deliver It Fast ',
            description: 'HANSA-FLEX South Africa ensures uninterrupted client operations by consistently stocking hoses and fittings, maintaining ample inventory for reliable and efficient hydraulic systems.'
        }
    ];




    const SAquality = {
        image: imgSA1,
        title2: 'Our Offerings ',
        listItems: ["Holistic solutions and a wide variety of hydraulic supplies", "Fully equipped OEM workshop for high-pressure hose assemblies to OEM standards ", "Metal Hose Division with qualified welders for fabricating metal hoses for extreme conditions, up to 8 inches in diameter "],
    }


    const SAFastdelivery = {
        image: HansaMap,
        title2: 'Industry Coverage',
        listItems: ["Johannesburg and Vereeniging branches are fully stocked to serve all industries, including the mining industry ", " Coastal branches in Durban and Cape Town serve all industries, including the marine industry", "Dedication to excellence, innovation, and customer satisfaction."],
    }



    const SAFastdelivery2 = {
        image: imgSAcustomerssatisfaction,
        title2: 'Customer satisfaction ',
        listItems: ["Customized Solutions: HANSA-FLEX offers bespoke hydraulic systems tailored to individual needs.", "Feedback Channels: Effective feedback channels ensure continual improvement and adaptation.", "Customer-Centric Service: Personalized, responsive support enhances customer satisfaction and loyalty."],
    }


    const featuresData2 = [
        // {
        //     title: "Global expertise",
        //     description: "We have over 30 years’ experience in the African market and 60 years of global expertise.",
        //     image: qualitySticker
        // },
        // {
        //     title: "Customer satisfaction ",
        //     description: "We are highly customer focused and service orientated.",
        //     image: customersatisfaction
        // },

        {
            title: "Always at your side",
            description: "We offer a 24/7 hydraulic service with fully equipped mobile vans, 365 days a year",
            image: service
        },

        // {
        //     title: "Technical expertise",
        //     description: "We have highly trained, professional, and experienced staff.",
        //     image: techexpert
        // },
        // {
        //     title: "BBBEE Certified",
        //     description: "We are committed to transformation and are currently a Level 3 BBBEE contributor with 30%+1 black ownership. ",
        //     image: BBEE
        // },
        {
            title: "Extensive branch & distribution network",
            description: "We have an international footprint with a dense branch & distribution network in South Africa and the African continent. ",
            image: glob
        },
        // {
        //     title: "African footprint",
        //     description: "We have a Dedicated exports sales team providing quality products to the whole African continent. ",
        //     image: exporsalesteam
        // },
        // {
        //     title: "Quality first approach.",
        //     description: "All products are subject to comprehensive testing within the HANSA-FLEX quality assurance system. ",
        //     image: qualitySticker
        // },
        {
            title: "ISO standards approved..",
            description: "We are committed to the highest standards. We are DIN EN ISO 9001:2015 certified!",
            image: isoLogo
        },
    ]
    const featuresData = [
        {
            title: "Highest quality standards",
            description: "Pipeline components that meet the customer’s requirements, manufactured precisely to the highest quality standards and in accordance with international standards.",
            image: imgMark
        },
        {
            title: "System Solutions from a single source",
            description: "One contact partner for everything and all components are designed to work together perfectly. ",
            image: imgMark
        },
        {
            title: "Decade of Experience",
            description: " Advice and manufacture by experienced experts with know-how extending across many industries.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];
    // const SATechnicalexpertise = {
    //     image: imgSATechnicalexpertise,
    //     title2: 'Technical expertise ',
    //     listItems: ["Expert Assistance: Our knowledgeable staff help customers choose the right solutions for their needs. ", "Guidance and Troubleshooting: Our team effectively troubleshoots issues, providing timely solutions.", "Support Excellence: Expert guidance ensures a seamless, informed experience, enhancing satisfaction and trust."],
    // }


    // const SAmap = {
    //     image: imgSAmap,
    //     title2: 'African Footprint ',
    //     listItems: ["Strategic Commitment: A dedicated export sales team in South Africa showcases HANSA-FLEX's commitment to the entire African continent. ", "Market Coverage: This team ensures access to HANSA-FLEX products and services across diverse African markets.", "Expertise and Resources: With specialized knowledge, the team meets local demands, providing tailored hydraulic solutions continent-wide."],
    // }


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgBremen}
                    title="Welcome to HANSA-FLEX South Africa "
                    paragraph="A family-owned public limited company (AG) headquartered in Bremen, Germany, HANSA-FLEX
                    operates globally as a leading supplier of hydraulic products, services, and comprehensive
                    solutions. With over 4,200 employees across 449 locations, we are committed to being the
                    preferred partner and service provider for our customers every day. Our expertise lies in a
                    diverse range of specialties. For over 60 years, our products and services have contributed to
                    the longevity, safety, and reliability of our customers’ machinery – both in Germany, Europe,
                    and beyond. With a history in South Africa dating back to the beginning of the millennium,
                    HANSA-FLEX South Africa today strategically operates four branches: Johannesburg,
                    Durban, Cape Town and Vereeniging, with a dedicated exports division ensuring our products
                    keep customer’s machines working throughout the African continent. These strategically
                    positioned branches ensure optimal supply wherever our customers are located." />
            </div>


            {/* <FullImageText
                imageSrc={imgSAFastdelivery}
                altText="Stage"
                title="Fast, flexible and personal"
                paragraph="HANSA-FLEX South Africa operates in Johannesburg, Vereeniging, Cape Town, and Durban, with two mobile branches at mines for rapid-response hydraulic services. Their fleet of ten 24/7 service vehicles is crucial for minimizing downtime and servicing remote areas. Plans are underway to expand this fleet to better serve customers nationwide."
                imageWidth={1}
            /> */}

            {/* <FullImageText 
             imageSrc={imgSA1}
             altText="Stage"
             title="Fast, flexible and personal"
             paragraph="HANSA‑FLEX blends owner-managed agility with international professionalism, ensuring high-quality fluid technology solutions worldwide. Cooperation, respect, and teamwork define our interactions, enabling us to keep customers' machines operational across borders."
             imageWidth={1}
            /> */}
            <div>
                <div data-aos={isMobile ? "fade-up" : "fade-up"}>
                    <ImageDescription
                        imageSrc={imgSA1}
                        title={""}
                        paragraphs={["Our offerings include holistic solutions and a wide variety of hydraulic supplies. Additionally, we boast a fully equipped OEM workshop, capable of creating critical high-pressure hose assemblies up to OEM standards and specifications. Furthermore, our Metal Hose Division, staffed by experienced welders, enables us to fabricate metal hoses for extreme conditions—up to 8 inches in diameter "
                        ]}
                        alignment={'center'}
                    />
                </div>
                <div data-aos={isMobile ? "fade-up" : "fade-up"}>
                    <ImageDescription
                        imageSrc={HansaMap}
                        paragraphs={["Our branches cater to diverse industries. The Johannesburg and Vereeniging branches are fully stocked to serve all industries including the mining industry, while our coastal branches in Durban and Cape Town serve all industries including the marine industry. At HANSA-FLEX South Africa we are dedicated to excellence, innovation, and customer satisfaction."
                        ]}
                        alignment={'center'}
                    />
                </div>
            </div>


            {/* <SectionHeader
                    title={"Why Choose us?"} /><br /> */}

            {/* </div>

            <div data-aos={isMobile ? "fade-up" : "fade-right"}>

                <ImageText data={SAquality} left={false} />
            </div>
            <div data-aos={isMobile ? "fade-up" : "fade-left"}>


                <ImageText data={SAFastdelivery} />
            </div>


            <div data-aos={isMobile ? "fade-up" : "fade-right"} > */}



            {/* <ImageText data={SAFastdelivery2} left={false} />
            </div>
            <br />
                <br />
                <br />
            <div data-aos={isMobile ? "fade-up" : "fade-left"}> */}



            {/* <ImageText data={SATechnicalexpertise} />
            </div>
            <br />
                <br />
                <br />
            <div data-aos={isMobile ? "fade-up" : "fade-right"}> */}



            {/* <ImageText data={SAmap} left={false} /> */}
            {/* <br />
            <br />
            <br /> */}


            <div className='div-gray center-all-items'>
                <SectionHeader
                    title="Why Choose HANSA-FLEX South Africa?"
                    subtitle="As a system partner HANSA-FLEX is an expert in the complex overall system of fluid technology and
                offers a full range of integrated services – from planning and consulting through engineering, unit
                engineering, the supply of fastening elements and assembly to commissioning and the training of
                operators."
                />
                <FeatureCardCarousel featuresData={featuresData2} />
            </div>

            <div>

                <br /><br /><br /><br />
                <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={1} />
            </div>

            <br />
            <br />

            <div data-aos={isMobile ? "zoom-in" : "zoom-in"} >
                <br /><br />
                <SectionHeader
                    title={"Quick facts about HANSA-FLEX International"} /><br />
                <NumberGrid numbers={numbers2} subtitles={subtitles2} /><br /><br />
            </div>
            <div data-aos={isMobile ? "zoom-in" : "zoom-in"} >
                <br /><br />
                <SectionHeader
                    title={"Quick facts about HANSA-FLEX South Africa"} /><br />
                <NumberGrid numbers={numbers} subtitles={subtitles} /><br /><br />
            </div>

            <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                <Row>
                    <Col xs={12} md={6}>
                        <h2>Contact Details:</h2>
                        <p>For inquiries, assistance, or collaboration opportunities, please reach out:</p>
                        <p> <MdLocationOn /> Address: <a href="https://maps.app.goo.gl/5xFPLgQosYunakjP8" style={{ textDecoration: 'none', color: 'white' }} >  HANSA-FLEX South Africa, 45 Steel Road, Spartan, Kempton Park, South Africa</a></p>
                        <p> <MdOutlineEmail /> Email: <a href="mailto:hst@hansa-flex.com" style={{ textDecoration: 'none', color: 'white' }}>     hst@hansa-flex.com</a></p>
                        <p> <FaPhoneVolume /> Phone: <a href="tel:+27 11 394 2966" style={{ textDecoration: 'none', color: 'white' }} >     +27 11 394 2966</a></p>
                        <p> <MdFax /> Fax: <a href="fax:+27 11 394 5836" style={{ textDecoration: 'none', color: 'white' }} >     +27 11 394 5836</a></p>
                    </Col>
                </Row>
            </div>

            {/* <div data-aos={isMobile ? "fade-up" : "fade-right"}
                style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                <Row>
                    <Col xs={12} md={6}>
                        <h2>Contact Details:</h2>
                        <div>
                            <p>
                                <MdLocationOn /> HANSA-FLEX South Africa Headquarters, 45 Steel Road, Spartan, Kempton Park, South Africa
                            </p>
                            <p>
                                <MdFax /> Fax: +27 11 394 5836
                            </p>
                            <p>
                                <MdPhone /> Telephone: +27 11 394 2966
                            </p>
                            <p>
                                <MdEmail /> Email: <a href="mailto:hst@hansa-flex.com" style={{ color: 'white' }}>hst@hansa-flex.com</a>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div> */}
        </div>
    )
}

export default AboutUsSA