import React, { useEffect } from 'react';
import HFTab from '../../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import imgMark from '../../../../assets/mark.png'

import Stage from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/Stage.jpg'
import OrderReplacement from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/OrderReplacement.jpg'
import UniquelyIdentify from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/UniquelyIdentify.jpg'
import PrincipleOfHose from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/PrincipleOfHose.jpg'
import Versatile from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/Versatile.jpg'
import Rapid from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/Rapid.jpg'
import Basic from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/Basic.jpg'
import XCode from '../../../../assets/Services/PreventativeMaintainance/XCodeHoseIdentificationSystem/XCode.jpeg'
import ImageText from '../../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import Promoter from '../../../../Components/Promoter/Promoter';
import imgTabletMobile from '../../../../assets/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'


const XCodeIdentification = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: OrderReplacement,
        title: 'Order replacement parts error-free and as quickly as possible',
        description: 'Every X-CODE contains the precise specification of a unique hose line in a specific use environment. After providing the six-digit alphanumeric code in any HANSA‑FLEX branch in the world, you can have an identical replacement part manufactured and delivered as quickly as possible to the place of use. Procurement of replacement parts could not be easier, quicker or more precise.',
    }

    const data2 = {
        image: UniquelyIdentify,
        title: 'Uniquely identify each hose line',
        description: "To make each hose line uniquely identifiable, the X-CODE is permanently attached in an easily visible position. Depending on the installation situation and the customer’s requirements, HANSA‑FLEX can provide different forms of attached identification such as Tip Tags, self-adhesive labels or stainless steel plates. QR codes and RFID chips can be integrated to allow them to be read by machine.",
    }

    const data3 = {
        image: PrincipleOfHose,
        title: 'Principles of digital hose management',
        description: 'Each X-CODE is a kind of digital profile containing all the relevant information about the component to which it is attached. These data are stored in a customised X-CODE database, which we make available to our customers free of charge in the My.HANSA‑FLEX customer portal. Using our X-CODE Manager hose line management software, you can add or edit these data and use them for preventive maintenance of your hydraulic systems.',
    }

    const data4 = {
        image: Versatile,
        title: 'Versatile, efficient and service-focused',
        description: 'The X-CODE self-adhesive label is particularly suitable for tight installation situations It attaches directly to the hose line and is protected by a transparent laminate from external influences such as oil, rubbing and weathering. The obligatory information such as works number, date of manufacture and maximum operating pressure are no longer embossed as usual on the fitting but printed in one of four information blocks in a highly legible manner on the label. By integrating the document and item numbers, customer-specific descriptions and material numbers and the QR code version of the X-CODE, this method of identification fulfils practical service functions for the customer.',
    }

    const featuresData = [
        {
            title: "Quick, simple and precise",
            description: "No measurements needed, X-CODE ensures the replacement part is 100% suitable.",
            image: imgMark
        },
        {
            title: "Individualised description",
            description: "Customer-specific information can be integrated in the form of a self-adhesive label at the time of creation.",
            image: imgMark
        },
        {
            title: "Machine readable",
            description: "QR codes and RFID chips are used to enable error-free digital capture of the data.",
            image: imgMark
        },
        {
            title: "Universal in use",
            description: "X-CODEs can be used for other components or complete component groups.",
            image: imgMark
        }
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"AS UNMISTAKABLE AS A FINGERPRINT"}
                    paragraph={"X-CODE, the coding and identification system from HANSA‑FLEX, has been proven in practice millions of times. Each individual hose line is uniquely identified based on its X-CODE and a suitable replacement part can be obtained in the shortest possible time anywhere in the world. X-CODE gives every hose line a digital profile containing all the relevant information. It forms the basis for service planning as part of preventive maintenance."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"ONE CODE FOR EVERY INSTANCE"}
                    subtitle={"The unique X-CODE combines all the information about a specific hose line, forms the basis for ordering a replacement and hose management."}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            {/* TODO: Carousel */}

            <div>
                <SectionHeader
                    title={"X-CODE MAKES YOUR WORK EASIER IN MANY WAYS"}
                    subtitle={"X-CODE is a key tool in considerably simplifying preventive maintenance in hydraulics. Find out the advantages of X-CODE at a glance."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <Promoter
                    title={"X-CODE MANAGER – THE EFFECTIVE HOSE MANAGEMENT SOFTWARE"}
                    checklistItems={["The best overview of your hose lines, machines and service dates: individual profile for each hose line including machine assignment, mobile working even without the Internet, module for performing inspections in accordance with BetrSichV, and many more features. Available in three licence packages."]}
                    btnLabel1={"TEST THE APP FREE OF CHAR"}
                    btnLabel2={"FIND OUT MORE"}
                    btnLabel1Link={"https://my.Hansa-Flex.in/en_GB/login"}
                    btnLabel2Link={"/x-code-manager"}
                    imageUrl={imgTabletMobile}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THE X-CODE SELF-ADHESIVE LABEL"}
                    subtitle={"The X-CODE self-adhesive label is particularly suitable for tight installation situations."}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"CODING OF YOUR EXISTING HOSE LINES"}
                    subtitle={"You have hose lines in use that are not yet coded and you would like to work with the X-CODE system from HANSA‑FLEX? Speak to your HANSA‑FLEX adviser about follow-up coding of your hose lines. If you do not yet have a named contact person, visit your nearest branch or contact us."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"X-CODE IN PRACTICE"}
                    subtitle={"X-CODE makes many HANSA‑FLEX services even more valuable to you."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Rapid} learnMoreLink={"/mobile-rapid-hydraulic-service"}
                                title="Rapid Hydraulic Service: even quicker thanks to X-CODE"
                                desc={"State the X-CODE when placing your order and our service technicians will usually have the appropriate parts on board immediately."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={Basic} learnMoreLink={"/hose-management"}
                                title="The basis for hose management"
                                desc={"Plan the regular inspection and servicing of your hose lines to ensure your hydraulic systems operate safely and in compliance with the law at all times."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={XCode} learnMoreLink={"/x-code-manager"}
                                title="X-CODE industrial hose lines"
                                desc={"More and more customers are using the extensively proven identification and coding system for hydraulics for their industrial hose lines."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default XCodeIdentification