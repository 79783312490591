import React from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import imgPressure_Centerspread from '../../assets/Services/Pressure accumulator inspection/Pressure_Centerspread.jpg'
import ImageText from '../../Components/ImageText/ImageText';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgPressure_Imagetext_01 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-01.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png'
import imgPressure_Imagetext_02 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-02.jpg'
import imgPressure_Imagetext_03 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-03.jpg'
import imgPressure_Imagetext_04 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-04.jpg'
import imgPressure_Imagetext_05 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-05.jpg'
import imgPressure_Imagetext_06 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-06.jpg'
import imgPressure_Imagetext_07 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagetext-07.jpg'
import ImageCard from '../../Components/ImageCard/ImageCard';
import imgPressure_Imagecard_01 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagecard-01.jpg'
import imgPressure_Imagecard_02 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagecard-02.jpg'
import imgPressure_Imagecard_03 from '../../assets/Services/Pressure accumulator inspection/Pressure_Imagecard-03.jpg'
import { Container, Row, Col } from 'react-bootstrap';

const Pressureaccumulatorinspection = () => {
  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Pressure_Imagetext_01 = {
    image: imgPressure_Imagetext_01,
    title: 'Inspection of hydraulic accumulators by officially authorised persons',
    description: ['At HANSA-FLEX, a large number of employees throughout Germany are qualified as competent persons for pressure equipment. As fluid experts, they also have extensive experience with pressure accumulators, provide expert advice on all questions and are always ready to help you. HANSA-FLEX offers its customers the following services:.'],
    listItems: ['Performance of recurring inspections', 'Support in the preparation of risk assessments', 'Documentation of inspection results in accordance with BetrSichV'],
  }


  const Pressure_Imagetext_02 = {
    image: imgPressure_Imagetext_02,
    title: 'Large pressure accumulators with an operating pressure of more than 1 bar',
    description: ['All hydro storage tanks with a gas volume of more than 10 l are considered to be systems requiring monitoring and are subject to special inspection obligations and regular inspections in accordance with the German Ordinance on Industrial Safety and Health (BetrSichV) during commissioning. Acceptance after installation as well as all periodic tests on these pressure accumulators may only be carried out by experts from an approved monitoring body (ZÜS) or a company commissioned by the latter, such as TÜV or DEKRA. The deadlines for the periodic inspections are based on the safety assessment and must be determined no later than 6 months after commissioning and checked by a ZÜS.'],
  }
  const Pressure_Imagetext_03 = {
    image: imgPressure_Imagetext_03,
    title: 'Medium-pressure accumulators with an operating pressure of not more than 1 bar',
    description: ['Hydropneumatic pressure accumulators with a gas volume between 1 and 10 l must be tested both before commissioning and at recurring intervals. All tests must be carried out by a qualified person for pressure hazards (TRBS 1203 No. 3.2).The operator independently determines the test periods on the basis of the information provided by the manufacturer, the mode of operation and his own experience. The test intervals specified in the BetrSichV are for guidance only.'],

  }

  const Pressure_Imagetext_04 = {
    image: imgPressure_Imagetext_04,
    title: 'Small pressure accumulators with an operating pressure of not more than 0.5 bar',
    description: ['Pressure accumulators with a gas volume of between 0.1 l and 1 l are classified as pressure systems that do not require monitoring and are considered work equipment within the meaning of the BetrSichV.Testing prior to commissioning is not mandatory, but the operator must nevertheless determine appropriate protective measures in accordance with the risk assessment drawn up. This also includes the performance of a periodic inspection in accordance with §10 of the BetrSichV by a qualified person for hazards caused by pressure in accordance with TRBS 1203 No. 2.'],
  }
  const Pressure_Imagetext_05 = {
    image: imgPressure_Imagetext_05,
    title: 'Inspection before bringing into first use',
    description: ['Before commissioning a pressure accumulator, in addition to the technical functionality, it is checked whether a CE marking, operating instructions, the identification number of the notified body and the necessary safety equipment are available. Depending on the category of the accumulator, the test is carried out from different bodies:'],
    listItems: ['More than 10 l gas volume: Approved monitoring body (ZÜS)', 'Up to 10 l gas volume: Qualified person for hazards due to pressure according to TRBS 1203 No. 3.2', 'Less than 1 l gas volume: Qualified person according to TRBS 1203 No. 2']
  }
  const Pressure_Imagetext_06 = {
    image: imgPressure_Imagetext_06,
    title: 'Recurring inspections',
    description: ['As part of the periodic inspections, it is ensured that the CE marking and operating instructions are available and that the equipment with safety function is still present, functional and correctly adjusted. In addition, it is checked whether the prerequisites for the next test interval are still correct. You should definitely have the following documents ready:'],
    listItems: ['Instruction manual and risk assessment', 'All documents of the manufacturer of the hydraulic accumulator', 'The operational documents of the commissioning test']
  }


  const Pressure_Imagetext_07 = {
    image: imgPressure_Imagetext_07,
    title: '',
    description: [''],
    listItems: ['Advice on the selection and design of pressure accumulators', 'Sales of pressure accumulators, accessories and repair kits', 'Installation of pressure accumulators', 'Maintenance and repair by officially authorised persons', 'Training and seminars on the servicing and operation of pressure accumulators']
  }



  const featuresData = [
    {
      title: "Qualified specialist personnel",
      description: "Inspections performed by officially authorised persons (TRBS 1203 No. 3.2 and No. 2).",
      image: imgMark
    },
    {
      title: "No compromise on safety",
      description: "Ensure compliance with all the requirements of the German Industrial Safety Act.",
      image: imgMark
    },
    {
      title: "Comprehensive support",
      description: "We support you in the preparation of risk assessments and your inspection and testing plans..",
      image: imgMark
    },
    {
      title: "Advice from experts",
      description: "Our experts advise you on the choice, design and operation of your pressure accumulator system..",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];










  return (
    <div>

      <CenterStage
        imageSrc={imgPressure_Centerspread}
        title={"PRESSURE ACCUMULATOR INSPECTIONS ARE OBLIGATORY"}
        paragraph={"Pressure accumulators are among the systems that need to be monitored by law and must therefore be checked regularly by trained specialist personnel who are officially authorised persons in accordance with the German Industrial Safety Act (BetrSichV). In addition, a safety assessment (risk assessment) must be prepared to ensure the safe operation of machines and systems with inbuilt hydraulic accumulators."}
      />



      <div >
        <br />
        <ImageText data={Pressure_Imagetext_01} />
      </div>


      <div >
        <SectionHeader
          title={"HANSA‑FLEX SERVICES OFFER ALL THESE ADVANTAGES"}
          subtitle={"With HANSA‑FLEX, the leading experts in fluid technology, you always have an experienced partner in pressure equipment technology at your side.."}
        />
      </div>


      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div >
        <SectionHeader
          title={"CLASSIFICATION OF PRESSURE ACCUMULATORS"}
          subtitle={"The scope of the inspections depends on the category of the pressure accumulator. Hydraulic accumulators are divided into systems that need to be monitored and systems that do not need to be monitored in accordance with the European Pressure Equipment Dir"}
        />
      </div>
      <div >
        <br />
        <ImageText data={Pressure_Imagetext_02} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Pressure_Imagetext_03} />
      </div>

      <div >
        <br />
        <ImageText data={Pressure_Imagetext_04} left={false} />
      </div>

      <div >
        <SectionHeader
          title={"EXPERT ADVICE"}
          subtitle={"A defective bladder indicates there was too much or too little gas pressure in the hydraulic system. The HANSA‑FLEX specialists are always pleased to advise you."}
        />
      </div>

      {/* TODO: Quote_NAME desc */}

      <div >
        <SectionHeader
          title={"INSPECTION INTERVALS FOR PRESSURE ACCUMULATOR SYSTEMS"}
          subtitle={"The inspection intervals for systems that require to be monitored are defined in the German Industrial Safety Act (BetrSichV). The definitive parameter for this classification is the permissible operating pressure safeguarded by the safety equipment."}
        />
      </div>

      {/* redbanner: carrousel */}



      <div >
        <SectionHeader
          title={"PROCESS AND SCOPE OF PRESSURE ACCUMULATOR INSPECTIONS"}
          subtitle={""}
        />
      </div>


      <div >
        <br />
        <ImageText data={Pressure_Imagetext_05} />
      </div>

      <div >
        <br />
        <ImageText data={Pressure_Imagetext_06} left={false} />
      </div>

      <div >
        <SectionHeader
          title={"TRAINING AND SEMINARS ON PRESSURE ACCUMULATORS IN HYDRAULIC SYSTEMS"}
          subtitle={"In cooperation with the International Hydraulics Academy (IHA), HANSA-FLEX runs seminars for installers and pressure accumulator maintenance personnel. Experts pass on their specialist knowledge of the safe operation, proper filling and repair of pressure accumulators."}
        />
      </div>

      [4/2 10:39 AM] 90000692
      {/* TODO: imagetext_Carrousel */}


      <div >
        <SectionHeader
          title={"ALL-ROUND CAREFREE SERVICE FROM THE FLUID SPECIALISTS"}
          subtitle={"HANSA-FLEX is your competent partner for all the tasks required for the installation and operation of pressure accumulators."}
        />
      </div>

      <div >
        <br />
        <ImageText data={Pressure_Imagetext_07} left={false} />
      </div>

      <div >
        <SectionHeader
          title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
          subtitle={""}
        />
      </div>


      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgPressure_Imagecard_01} learnMoreLink="/pressure-accumulators-and-associated-systems" title="Repair and overhaul of pressure accumulator systems" desc="systemsHANSA-FLEX Industrial Service supports you with all servicing and maintenance work on your pressure accumulator systems, including nitrogen filling or their replacement." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgPressure_Imagecard_02} learnMoreLink="/metal-hoses" title="Gas-filled pressure accumulators from HANSA-FLEX" desc="The HANSA-FLEX range includes numerous high-quality diaphragm and bladder accumulators of various designs from renowned manufacturers." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgPressure_Imagecard_03} learnMoreLink="/pressure-accumulators" title="Prevent wear" desc="The cleanliness of the hydraulic fluid affects the service life of components. Oil cleaning and regular analyses help you avoid system breakdowns." />
          </Col>


        </Row>
      </Container>



















    </div>
  )
}

export default Pressureaccumulatorinspection