import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'

import imgStage from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/stage.jpg'
import HoseFitting from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/HoseFitting.jpg'
import PipeFitting from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/PipeFitting.jpg'
import Adapter from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/Adapter.jpg'
import BallValves from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/BallValves.jpg'
import Flanges from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/Flanges.jpg'
import Couplings from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/ouplings.jpg'
import Pipeline from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/Pipeline.jpg'
import SuitableHose from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/SuitableHose.jpg'
import Stock from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/Stock.jpg'
import Design from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/Design.jpg'
import SealingTech from '../../../assets/Products/HydraulicConnectionTechnology/HydraulicConnectionTechnology/SealingTech.jpg'



const HydraulicConnectionTechnology = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "HF-Form pipe forming system",
            description: "Durable and safe pipe connections – without a cutting ring, special parts or special tools",
            image: imgMark
        },
        {
            title: "Proven quality",
            description: "All HANSA‑FLEX products fulfil the highest quality standards and are subject to extensive testing",
            image: imgMark
        },
        {
            title: "Installation made easy",
            description: "With the HANSA‑FLEX Industrial Service for machines and systems of any size",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    const Data1 = {
        title: 'A pipeline for every situation',
        description: 'Steel, stainless steel, copper or aluminium: bent pipes made from precision tube in various materials and surface finishes are available from HANSA‑FLEX.',
        listItems: ["Design and series manufacture of bent pipes of all types", "Highest precision and Just-In-Sequence delivery", "Single pipes, batches, component groups and ready-to-install pipe assemblies"],
        buttonName: "READ MORE",
        buttonLink: "/pipelines",
        image: Pipeline
    }

    const Data2 = {
        title: 'A suitable hose for every application',
        description: 'For low, medium and high pressures: the wide range of HANSA‑FLEX hydraulic hoses includes different versions, such as spiral and textile hoses, and has every field of use covered.',
        listItems: ["From mobile applications to stationary hydraulic systems", "Designed and assembled to your individual requirements", "Hose fittings perfectly matched to one another – including in stainless steel"],
        buttonName: "READ MORE",
        buttonLink: "/hoses-and-hose-lines",
        image: SuitableHose
    }

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"EVERYTHING RUNNING SMOOTHLY: HYDRAULIC CONNECTION TECHNOLOGY"}
                    paragraph={"Hydraulic connecting elements, such as adapters, fittings and threaded connectors, are an essential means of joining components in hydraulic systems. They ensure that energy can be safely transferred between different components. HANSA‑FLEX offers an extensive portfolio in the field of connection technology – with most items generally available from stock at all times. All products meet the highest quality standards, confirmed in product testing."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SAFELY CONNECTED"}
                    subtitle={"Our fluid and hydraulics experts are here to advise you on the choice of suitable components. HANSA‑FLEX can produce customised special solutions to suit individual needs because it has its own manufacturing facilities."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={HoseFitting} learnMoreLink={"/hose-fitting"}
                                title="Hose fittings"
                                desc={"To ensure components remain safely connected, even under high pressures, HANSA‑FLEX hose fittings are designed to perfectly match the hydraulic hoses. A zinc-"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={PipeFitting} learnMoreLink={"/pipe-fitting"}
                                title="Pipe fittings"
                                desc={"Pipe fittings rely on a tight positive fit with one another to create a safe connection, even when subjected to high working pressures, pressure peaks and vibrations."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Adapter} learnMoreLink={"/adapters"}
                                title="Adapters"
                                desc={"Threaded, transition or hose end adapters: adapters create a safe and leak-free connection between components with differing thread types."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={BallValves} learnMoreLink={"/ball-valves"}
                                title="Ball valves"
                                desc={"Ball valves are used to shut off, control and regulate flow in pipe systems up to pressures of 500 bar and thus avoid the need to convert or uninstall components."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Flanges} learnMoreLink={"/flanges"}
                                title="Flanges"
                                desc={"Flanges can be simply installed without the need for applying large amounts of force and in congested locations, even for large diameter components."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Couplings} learnMoreLink={"/coupling"}
                                title="Couplings"
                                desc={"Swap attachments, connect machines: couplings are ideal for when components need to be quickly connected and disconnected on mobile and stationary"} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"THREADED TECHNOLOGY IN PROVEN HANSA‑FLEX QUALITY "}
                    subtitle={"Connections between different components must be robust and durable under high working pressures and extreme loads."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"HYDRAULIC HOSE LINES AND PIPELINES FROM HANSA‑FLEX"}
                    subtitle={"As a system provider, HANSA‑FLEX can supply hydraulic hose lines for everything from mobile applications on construction sites right up to stationary hydraulic systems in the food industry."}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={Stock} learnMoreLink={"/Logisticssolutions"}
                                title="Well-managed component stocks"
                                desc={"The right quantity at the right time in the right place: with HANSA‑FLEX you gain from optimised stock management including delivery directly to your manufacturing"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Design} learnMoreLink={"/plant-project-planning-and-management"}
                                title="Optimised system design"
                                desc={"Our hydraulics specialists advise and assist you in planning your hydraulic system and ensure the whole system is designed to be efficient."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={SealingTech} learnMoreLink={"/sealing-tech"}
                                title="Reliable sealing technology"
                                desc={"The HANSA‑FLEX sealing technology production facilities manufacture seals to individual customer requirements up to a diameter of 2,500 mm as one-off or series..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default HydraulicConnectionTechnology