import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgmissionStatement from '../../assets/MissionStatement/imgMissionStatement.jpg'
import ImageText from '../../Components/ImageText/ImageText';
import imgTogetherness from '../../assets/MissionStatement/imgToghetherness.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import imgresponsibiliy from '../../assets/MissionStatement/imgResponsibility.jpg'
import imgReliability from '../../assets/MissionStatement/imgReliability.jpg'
import imgEnviromentalAwarness from '../../assets/MissionStatement/imgEnvironmentalawareness.png'
import imgRD from '../../assets/MissionStatement/imgR&D.png'
import imgFlexibility from '../../assets/MissionStatement/imgFlexibility.png'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard';
import imgEnrich_Carrier from '../../assets/MissionStatement/Enrich_Carrier.jpg'
import imgFocused1962_history from '../../assets/MissionStatement/Focused1962_history.jpg'
import FullImageCarousel from '../../Components/FullImageCarousel/FullImageCarousel';
import imgRespect from '../../assets/MissionStatement/Respect.jpg'
import RedSectionHeader from '../../Components/Redsectionheader/RedSectionHeader';

function GroupMissionStatement() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Togetherness = {
        image: imgTogetherness,
        title: 'Togetherness',
        listItems: ['We treat each other with appreciation and respect.', 'We act in a cooperative manner and support each other', 'We communicate with one another openly and honestly.', 'We make sure that we and others are always well-informed.', 'We are part of a larger whole'],
    }

    const Responsibiliy = {
        image: imgresponsibiliy,
        title: 'Responsibility',
        listItems: ['We deliver quality and expect the same from our partners', 'We encourage our staff to work on their own initiative.', 'We question things critically and respect the opinions of others..', 'We are aware of our responsibilities towards colleagues and partners and implement these responsibilities.'],
    }


    const Reliability = {
        image: imgReliability,
        title: 'Reliability',
        listItems: ['We keep our promises', 'We trust our colleagues snd partners.', 'We represent security.'],
    }


    const EnviromentalAwarness = {
        image: imgEnviromentalAwarness,
        title: 'EnviromentalAwarness',
        listItems: ['We conserve natural resources.', 'We promote energy efficiency in hydraulics with regard to our customers, products and services.', 'We ensure that our processes are sustainable.'],
    }

    const RD = {
        image: imgRD,
        title: 'R&D',
        listItems: ['We develop innovative products and services.', 'We continuously expand our professional expertise products and services.', 'We encourage and challenge our workforce.', 'We support our partners with consultancy and training.', 'We all work together to ensure the success of HANSA‑FLEX.'],
    }

    const Flexibility = {
        image: imgFlexibility,
        title: 'Flexibility',
        listItems: ['We respond to the individual requirements of our partners', 'We offer our colleagues flexible opportunities to work throughout the group', 'We identify changes at an early stage and respond immediately']
    }


    const carouselData = [
        {
            imageSrc: imgRespect,
            title: 'The HANSA-FLEX CODE OF CONDUCT',
            subtitle: 'As a company we are highly aware of our role in society and our responsibilities towards our partners and our workforce. We are therefore committed to clear guiding principles which form a framework for our daily operations.',
            paragraph: 'We call on our partners to apply similar business principles. In accordance with our corporate responsibilities we are committed to the following basic principles:',
            listItems: ["We observe the laws and regulations of all countries, as they are our top priority. Should there be any lack of legal regulation, our Mission Statement and Code of Conduct apply in its stead.", "We are responsible for safety in our working environment, which all our employees are privy to and regularly instructed in. For us, responsible action is an essential part of avoiding dangers or hazards.", " We commit ourselves and our employees to protect natural resources in the long term by acting in an environmentally conscious manner."],
        },
        {
            imageSrc: imgRespect,
            title: 'The HANSA-FLEX CODE OF CONDUCT',
            subtitle: 'As a company we are highly aware of our role in society and our responsibilities towards our partners and our workforce. We are therefore committed to clear guiding principles which form a framework for our daily operations.',
            paragraph: 'We call on our partners to apply similar business principles. In accordance with our corporate responsibilities we are committed to the following basic principles:',
            listItems: ["We support and respect international human rights, with neither forced labour nor child labour being tolerated. We treat employees and business partners with appreciation and respect.", "We provide equal treatment to all, regardless of gender, race, religion, age, disability, sexual orientation, nationality, political conviction, trade union membership, social or ethnic origin or other personal characteristics.", "We don’t tolerate corruption. In connection with our business operations, no advantageous personal benefits, gifts or invitations may be requested, granted, accepted or offered.", "We maintain political neutrality as part our business operations and do not support any activities otherwise."],
        }

    ];








    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgmissionStatement}
                    title={"A FOCUS ON TOGETHERNESS"}
                    paragraph={"The HANSA‑FLEX Mission Statement forms the orientation framework for the daily actions of all employees worldwide. The underlying values and principles were developed in joint workshops by employees, managers and the owner families. Together with our HANSA‑FLEX Code of Conduct, we have a stable framework of values forming the basis of our corporate culture."}
                />
            </div>

            <div className='div-gray'>
                <SectionHeader
                    title={"OUR DRIVE IS IN OUR DNA"}
                    subtitle={"The values and principles of our Mission Statement exemplify HANSA‑FLEX’s commitment to its customers, guiding us in our daily operations both internal and external."}
                />

                <div >
                    <br />
                    <ImageText data={Togetherness} left={false} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={Responsibiliy} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={Reliability} left={false} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={EnviromentalAwarness} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={RD} left={false} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={Flexibility} /> <br /><br />
                </div>


                <div>
                    <SectionHeader
                        title={"FIRMLY ANCHORED IN OUR DAILY INTERACTIONS"}
                        subtitle={"Company-wide campaigns, local initiatives and the work of the cross-departmental Mission Statement group keep the values of HANSA‑FLEX alive and up to date in everyday work life."}
                    />

                </div>

                {/* <div>
                    <RedSectionHeader title={"Hoses perfectly designed for pneumatic applications"}
                        paragraph={"The HANSA‑FLEX online shop has a large selection of air hoses in a wide range of diameters, lengths and materials such as PA, PE, PUR, PVDF or PVC. The distinct colours of the hoses in combination with many other safety devices ensure maximum safety."}
                        btnLabel={"DISCOVER NOW"}
                        btnLabelLink={'/'}
                    />

                </div> */}

                <div>
                    <br />
                    <br />
                    <br />
                    <FullImageCarousel carouselItems={carouselData} />

                </div>

            </div>
            <Container className="custom-container">
                <Row>
                    {/* <Col sm={12} md={6} lg={4}><ImageCard image={imgEnrich_Carrier} learnMoreLink={"/hf-group-mission-statement"}
                            title="Value-based cooperation"
                            desc={"HANSA‑FLEX stands for fairness, respect and transparency. Our Mission Statement and Code of Conduct apply at all locations worldwide."} /></Col> */}

                    <Col sm={12} md={6} lg={4}><ImageCard image={imgFocused1962_history} learnMoreLink={"/hf-group-history"}
                        title="Focused on customers since 1962"
                        desc={"From a one-man company to a global family business, HANSA‑FLEX first began in a garage in Achim, near Bremen, in 1962."} /></Col>


                </Row>
            </Container>



        </div>
    );
}

export default GroupMissionStatement;