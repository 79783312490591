import React from 'react';
import './SectionHeader.css';

const SectionHeader = ({ title, subtitle, paragraph, titleSize }) => {
  const isMobile = window.innerWidth <= 768;

  if (!titleSize) {
    if (isMobile) titleSize = "1.8rem";
    else { titleSize = "3rem" }
  } else {
    if (isMobile) titleSize = "1.8rem";
  }

  return (
    <div className="section-header">
      <h1 className="title" style={{ color: '#28588F', fontSize: titleSize }}>{title}</h1>
      <p className="subtitle">{subtitle}</p>
      <p className="paragraph">{paragraph}</p>
    </div>
  );
};

export default SectionHeader;
