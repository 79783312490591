import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../PageCss/HansaFlexIndiaPages/Journey.css';
import JourneyCard from '../../Components/JourneyCard/JourneyCard';
import img2018 from '../../assets/Journey/2018.jpg';
import img2020 from '../../assets/Journey/2020.jpg';
import img2021 from '../../assets/Journey/2021.jpg';
import img2022 from '../../assets/Journey/2022.jpg';
import img2023 from '../../assets/Journey/2023 (2).jpg';
import JourneyCardR from '../../Components/JourneyCard/JourneyCardR';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgHistoryStage from '../../assets/company-history.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import imgSA_journey from "../../../src/assets/Journey/SA_journey.jpg"
import imgSA2001 from "../../../src/assets/Journey/SA2001.jpg"
import imgSAMinning from "../../../src/assets/Journey/SAMinning.jpg"
import imgSAMarine from "../../../src/assets/Journey/SAApprovals1.png"
import imgSATradeactivities from "../../../src/assets/Journey/SATradeactivities.jpg"
import imgSAacquired from "../../../src/assets/Journey/SAacquired.png"
import SAbanner from "../../../src/assets/Journey/SAbanner.jpg"
import SA_journeynew from "../../../src/assets/Journey/SA_journeynew.jpg"
import SA_new from "../../../src/assets/Journey/SA_new.jpg"
import SAcombine from "../../../src/assets/Journey/SAcombine.jpg"
import img2016 from "../../../src/assets/Journey/web_Outside_front.jpg"
import imgMilestone2009 from "../../../src/assets/Journey/two-confident-business-man-shaking-hands-meeting-office-success-dealing-greeting-partner-concept.jpg"


function Journey() {

    const isMobile = window.innerWidth <= 789;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
        AOS.init({
            // Global settings:
            disable: false,
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate',
            useClassNames: false,

            // Settings that can be overridden on per-element basis:
            offset: 300,
            delay: 0,
            duration: 500,
            easing: 'ease',
            once: false,
            mirror: false,
            anchorPlacement: 'top-bottom',
        });
    }, []);



    return (
        <div className='journey-content'>
            <CenterStage imageSrc={SAcombine} title="Come with us on our Journey !" paragraph="HANSA-FLEX South Africa has a rich history marked by strategic evolution and adaptability. Navigating through time, we have demonstrated resilience and innovation, continually expanding our influence in the industry." />

            <div className='div-timeline-wrapper'>
                <br /><br /><br /><br />
                {isMobile ? <></> : <><div className='vertical-line'></div></>}

                <div>
                    <JourneyCard
                        title="2001"
                        description="HANSA-FLEX opens it’s first branch in Port Elizabeth, South Africa. The quality of the HANSA-FLEX’S products and problem-solving expertise quickly won over companies from a wide range of
                        industries."
                        imageUrl={imgSA2001}
                        additionalDescription="HANSA-FLEX opens in Port Elizabeth."
                    />
                </div>

                {/* <div >
                    <JourneyCardR
                        title=""
                        description="One of our main advantages was that international companies, such as those in the mining industry, were already familiar with HANSA‑FLEX from other regions of the world. "
                        imageUrl={imgSAMinning}
                        additionalDescription="HANSA-FLEX SA: Mining Hydraulics Partner!"
                    />
                </div> */}

                <div >
                    <JourneyCardR
                        title="2007"
                        description="The majority of shares in HST (Hydraulic Steel Tube (Pty) Ltd) were bought by HANSA-FLEX
                        International GmbH because of its dominance and knowledge in the South African market. The acquisition included branches in Johannesburg, Durban and Cape Town."
                        imageUrl={imgSAacquired}
                        additionalDescription="HANSA-FLEX South Africa Acquired HST"
                    />
                </div>

                <div >
                    <JourneyCard
                        title="2009"
                        description="Amalgamation of HST (Hydraulic Steel Tube (Pty) Ltd) with HANSA-FLEX South Africa. The
                        company has since been trading as HST HANSA-FLEX Southern Africa."
                        imageUrl={imgMilestone2009}
                        additionalDescription="Amalgamation of HST with HANSA-FLEX South Africa"
                    />
                </div>

                <div>
                    <JourneyCardR
                        title="2013"
                        description="HANSA-FLEX South Africa opened its fourth branch in Vereeniging after it saw the need for a
                        trusted hydraulic supplier to serve the agricultural and steel industry in Vereeniging and the
                        surrounding areas."
                        imageUrl={imgSATradeactivities}
                        additionalDescription="HANSA-FLEX opens in Vereeniging"
                    />
                </div>

                <div>
                    <JourneyCard
                        title="2016"
                        description="The Mobile Service division, to become known as HFSA24, began in September 2016 with the
                        acquisition of a small mobile servicing business of 4 mobile vehicles. The fleet has since grown to 10
                        operational vehicles servicing Gauteng, Kwazulu-Natal and Cape Town regions.
                        "
                        imageUrl={img2016}
                        additionalDescription="The Mobile Service division"
                    />
                </div>

                <div>
                    <JourneyCardR
                        title="2018"
                        description="HANSA-FLEX South Africa opens a major central warehouse in Spartan, Johannesburg."
                        imageUrl={imgSAMarine}
                        additionalDescription="Warehouse in Spartan, Johannesburg"
                    />
                </div>
            </div>
        </div>
    );
}

export default Journey;
