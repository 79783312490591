import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './JourneyCard.css'; // Import the CSS file for custom styling
import AOS from 'aos';
import 'aos/dist/aos.css';

function JourneyCard({ title, description, imageUrl, additionalDescription }) {

    const isMobile = window.innerWidth <= 789;


    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false,
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate',
            useClassNames: false,

            // Settings that can be overridden on per-element basis:
            offset: 400,
            delay: 0,
            duration: 500,
            easing: 'ease',
            once: false,
            mirror: false,
            anchorPlacement: 'top-bottom',
        });
    }, []);

    return (
        <div className="journey-card ">
            <Row>
                <Col className="no-gutters right " data-aos={isMobile ? "fade-up" : "fade-right"}>
                    <div className=" text-white j-card-container-l ">
                        <div className="image-container">
                            <img src={imageUrl} alt="Journey" />
                            <h2 className="j-img-title">{title}</h2>
                        </div>
                        <div className='right'>
                            <p className="j-img-description">{description}</p>
                        </div>
                    </div>
                </Col>

                {
                    isMobile ?

                        <></>
                        : <><Col className="no-gutters left" data-aos="flip-down">

                            <div className="additional-desc">
                                <div className='vertical-line-point'></div>

                                <p>{additionalDescription}</p>
                            </div>
                        </Col></>
                }

            </Row>
        </div>
    );
}

export default JourneyCard;
