import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import ImageText from '../../Components/ImageText/ImageText'
// import imgServiceContainer from "../assets/Services/SA_Service2.jpg"
import imgServiceContainer from "../../assets/Services/SA_Service2.jpg"
import Promoter from '../../Components/Promoter/Promoter'
import imgTabletMobile from '../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import { BsCheckLg } from 'react-icons/bs'
import imgMark from '../../assets/mark.png';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgGlobalReach from '../../assets/Services/AfricanExportDivision/SA export.jpg'
import imgRenownedQuality from '../../assets/Services/AfricanExportDivision/Hansa-Flex-HD-LL-hose-large.jpg'
import imgCenterStage from '../../assets/Services/AfricanExportDivision/businessman-hand-pushing-button-connected-map-network-style-traditional-art.jpg'
import FeaturesCard from '../../Components/FeaturesCard/FeaturesCard'

const AfricanExportsDivision = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        title: "Global Reach, Local Expertise",
        description: "We collaborate with dealers in various African countries, from Cape Town to Addis Ababa. Our extensive branch and dealer network ensures that our products reach every corner of the continent.",
        image: imgGlobalReach
    }

    const data2 = {
        title: "Renowned Quality:",
        description: "We supply quality hydraulic components to ensure maximum uptime in the most remote sites in Africa. Whether it’s hydraulic valves, pumps, or cylinders, we’ve got you covered.",
        image: imgRenownedQuality
    }

    const data3 = {
        title: "",
        description: "Our on-site containers are strategically placed at mine sites across Africa. These containers house essential hydraulic components, ensuring quick access and minimal downtime in the most remote sites in Africa. Imagine having the right spare parts at your fingertips when a critical hydraulic system needs repair. Our containers make it possible, streamlining maintenance and reducing operational disruptions.",
        image: imgServiceContainer
    }

    return (
        <div>
            <CenterStage title={"OUR EXPORTS DIVISION: SEAMLESS HYDRAULIC PRODUCT EXPORT TO AFRICAN DEALERS"}
                paragraph={"At HANSA-FLEX South Africa we take pride in our seamless hydraulic product export services. Our mission? To empower dealers across Africa with top-quality hydraulic components."}
                imageSrc={imgCenterStage}
            />

            <ImageText data={data1} />
            <ImageText data={data2} left={false} />

            <SectionHeader title={"On-Site Containers for Mining Sites: Tailored Solutions"} />
            <ImageText data={data3} />

            <SectionHeader title={"Advantages of working with HANSA-FLEX"} />
            <div className='d-flex justify-content-center '>
                <div>
                    <FeaturesCard
                        title={"Reduced Lead Times"}
                        description={"Our efficient export process means shorter lead times for dealers. When you need a replacement part, we’re just a call away."}
                        image={imgMark}
                    />
                </div>
                <div style={{ width: 100 }}></div>
                <div>
                    <FeaturesCard
                        title={"Reliability"}
                        description={"Dealers trust us because we deliver consistently. No more waiting weeks for shipments—our commitment to reliability sets us apart."}
                        image={imgMark}
                    />
                </div>
            </div>

            <Promoter
                title={"Ready to elevate your hydraulic business? Contact us today Let’s build a stronger Africa together!"}
                btnLabel1={"Contact Now"}
                btnLabel2={"Explore Products"}
                btnLabel1Link={"/contact-us"}
                btnLabel2Link={"https://shop.Hansa-Flex.co.za/"}
                imageUrl={imgTabletMobile}
            />

        </div>
    )
}

export default AfricanExportsDivision