import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import HFTab from '../../../../Components/HfTabs/HfTabs'
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../../assets/mark.png'
import ImageText from '../../../../Components/ImageText/ImageText'

import Stage from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Stage.jpg'
import Inspection from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Inspection.jpg'
import Inspection2 from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Inspection2.jpg'
import InspectionWorkFlow from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/InspectionWorkFlow.jpg'
import Document from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Document.jpg'
import BasicUnit from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/BasicUnit.jpg'
import FluidTech from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/FluidTech.jpg'
import ControlSystem from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/ControlSystem.jpg'
import Inspection3 from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Inspection3.jpg'
import Inspection4 from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Inspection4.jpg'
import Inspection5 from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Inspection5.jpg'
import Reliable from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Reliable.jpg'
import Prevent from '../../../../assets/Services/PreventativeMaintainance/LiftingPlatformsAndLoadingBridges/Prevent.jpg'

const LiftingPlatformsAndLoadingBridges = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: Inspection,
        title: 'Inspection of loading bridges and yard ramps',
        description: 'We inspect your loading bridges, truck ramps, channel ramps, yard ramps and mobile loading ramps and ensure that all the safety-related requirements of DGUV Rule 308-002 (formerly BGG 945) are fulfilled at the time of inspection.',
        listItems: [
            "Visual inspection and function test including documentation",
            "Inspection of loading bridges, truck, channel and yard ramps",
            "Compliance with all the requirements of standard DIN EN 1398"
        ]
    }

    const data2 = {
        image: Inspection2,
        title: 'Inspection of lifting platforms',
        description: "The suitably qualified employees at HANSA‑FLEX have extensive experience with lifting platforms and assist you with their know-how and practical support with the regular inspection of your equipment in accordance with the legally prescribed requirements.",
        listItems: [
            "Visual inspection and function test including documentation",
            "Inspection of lifting work platforms, loading platforms, tilting and vehicle lifting platforms",
            "Performed in accordance with DGUV Rule 108-006 (formerly BGR 233)"
        ]
    }

    const data3 = {
        image: Inspection3,
        title: 'Inspections that are the manufacturer’s responsibility',
        description: 'The inspection before bringing into first use comprises the pre-inspection, construction inspection and final acceptance inspection and is the responsibility of the manufacturer. In the course of this inspection, a check is made to see that the equipment meets the essential health and safety requirements and that it will not endanger persons during its envisaged period of use. In addition, the manufacturer must document all steps in the inspection. Then the equipment receives the required CE marking necessary for its operation.',
    }

    const data4 = {
        image: Inspection4,
        title: 'Inspections that are the responsibility of the operator',
        description: 'If a lifting platform or ramp has not been delivered as ready for operation, the operator must check that it is ready for operation, including installation and equipment, before it is brought into first use. The operator is also responsible for performing extraordinary inspections and the regular 12-monthly inspections. All inspections must be carried out by suitably qualified persons and properly documented.',
    }

    const featuresData = [
        {
            title: "Qualified experts",
            description: "Authorised persons for lifting platforms and loading bridges perform the inspections.",
            image: imgMark
        },
        {
            title: "No compromise on safety",
            description: "Ensure compliance with all the requirements of the German Industrial Safety Act.",
            image: imgMark
        },
        {
            title: "Comprehensive support",
            description: "Preparation of mutually agreed inspection plans and the performance of maintenance services.",
            image: imgMark
        },
        {
            title: "Clearly documented",
            description: "Preparation and provision of inspection documentation in accordance with the legal requirements.",
            image: imgMark
        }
    ];

    //HfTab data 2
    const imageTextData2 = [
        {
            title: "Our procedures",
            description: "In addition to the visual inspection and function test, a properly conducted, complete inspection also includes a check of the documents associated with the equipment. Any defects found as part of the inspection are recorded in the documentation. The inspection also determines whether the equipment is ready for operation or whether it must be inspected again after rectification of the issues. After successful performance of the inspection, the date for the next regular inspection is noted in the inspection log. Then the equipment receives the inspection certification mark. This serves as confirmation for the operator and the user that the equipment meets all the legal requirements for its safe operation.",
            image: InspectionWorkFlow
        },
        {
            title: "The following documents and certificates are check",
            description: "The documents are examined briefly before the visual inspection and function test. In addition to checking that the documents are present, we also confirm that the documents and markings are complete and correct.",
            listItems: [
                "Inspection log",
                "Identification plate / CE marking",
                "Operating instructions",
                "Final acceptance inspection record (ramps)",
                "Load capacity sticker (lifting platforms)"
            ],
            image: Document
        },
        {
            title: "The basic unit is thoroughly tested",
            description: 'Wear of the components on the basic unit of lifting platforms, loading bridges and yard ramps is a natural consequence of regular use. We check to see that the following components are working correctly:',
            listItems: [
                "Frame/bearings",
                "Pins/fastenings",
                "End-stop buffers and pins/suspensions (ramps)",
                "Wheels/undercarriage and safety devices (ramps)",
                "Wheel stopper, axle play detector and step protection (lifting platforms)"
            ],
            image: BasicUnit
        },
        {
            title: "The fluid technology components are also inspected",
            description: 'Some components in hydraulic and pneumatic systems direct the fluid and use it for various functions. They are visually inspected and tested as part of the regular inspection.',
            listItems: [
                "Power unit and lifting cylinder/piston rod",
                "Pipelines and hose lines",
                "Extension cylinders/piston rods (ramps)",
                "Oil types, condition, quantity and the emergency lowering device (lifting platforms)",
                "Condition and sealing performance of the pneumatics (lifting platforms)"
            ],
            image: FluidTech
        },
        {
            title: "These components are tested in the control system",
            description: 'The control system is a key component of equipment and undergoes just as exacting and comprehensive inspection as the structural components for transferring and controlling forces. The following control elements are inspected or tested:',
            listItems: [
                "Emergency stop switch",
                "Protection against unintentional switching on",
                "Command devices",
                "End switch (ramps)",
                "Light barriers and lifting/lowering/move to engage latches (lifting platforms)"
            ],
            image: ControlSystem
        },
    ];

    const tabTitles2 = [
        { title: "Inspection workflow", eventKey: "Inspection workflow" },
        { title: "Documents", eventKey: "Documents" },
        { title: "Basic unit", eventKey: "Basic unit" },
        { title: "Fluid technology", eventKey: "Fluid technology" },
        { title: "Control system", eventKey: "Control system" },
    ];



    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"INSPECTIONS OF LIFTING PLATFORMS AND LOADING BRIDGES"}
                    paragraph={"Powered and hand-operated lifting platforms, yard ramps and loading bridges must be inspected at least once a year for their safe condition by a suitable expert. The operator is responsible for performing the inspection. The trained experts at HANSA‑FLEX perform the regularly prescribed inspections of this equipment in accordance with DGUV including documentation in accordance with the German Industrial Safety Act (BetrSichV)."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"RECURRING INSPECTIONS"}
                    subtitle={"The manufacturer performs the inspection before the component is brought into first use. The system operator is responsible for the subsequent professional inspections at regular intervals. The officially authorised persons for the inspection of lifting."}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA‑FLEX SERVICES OFFER ALL THESE ADVANTAGES"}
                    subtitle={"With HANSA‑FLEX, experienced specialist personnel perform every inspection. They are qualified as officially authorised persons for inspecting lifting platforms, loading bridges and yard ramps."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"LEGALLY COMPLIANT DOCUMENTATION BASED ON INSPECTION RECORDS"}
                />
                <HFTab imageTextData={imageTextData2} tabTitles={tabTitles2} />
            </div>

            <div>
                <SectionHeader
                    title={"INSPECTIONS BEFORE BRINGING INTO FIRST USE"}
                    subtitle={"An inspection must be carried out before the bringing into first use of lifting platforms and loading ramps to check for faulty construction and installation. The manufacturer or the manufacturer and the operator perform the inspection."}
                />
                <ImageText
                    data={data3} />
                <ImageText
                    data={data4} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Inspection5} learnMoreLink={"/inspection"}
                                title="Inspections by suitably qualified experts"
                                desc={"The experienced employees at HANSA‑FLEX also perform inspections of hydraulic and industrial hose lines and pressure accumulators in accordance with the..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={Reliable} learnMoreLink={"/repair-and-overhaul"}
                                title="Reliable servicing of your systems and machines"
                                desc={"HANSA‑FLEX Industrial Service supports you with all servicing and maintenance work, such as troubleshooting, overhauling of defective components or disposal."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={Prevent} learnMoreLink={"/fluid-service"}
                                title="Prevent wear"
                                desc={"The cleanliness of the hydraulic fluid affects the service life of components. Oil cleaning and regular analyses help you avoid system breakdowns."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default LiftingPlatformsAndLoadingBridges