import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgSeries_Centerspread from '../../../assets/Services/Production/Series Production/Series_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'
import imgSeries_Imagetext_01 from '../../../assets/Services/Production/Series Production/Series_Imagetext_01.jpg'
import imgSeries_Imagetext_02 from '../../../assets/Services/Production/Series Production/Series_Imagetext_02.jpg'
import imgSeries_Imagetext_03 from '../../../assets/Services/Production/Series Production/Series_Imagetext_03.jpg'
import imgSeries_Imagetext_04 from '../../../assets/Services/Production/Series Production/Series_Imagetext_04.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgSeries_Imagecard_01 from '../../../assets/Services/Production/Series Production/Series_Imagecard_01.jpg'
import imgSeries_Imagecard_02 from '../../../assets/Services/Production/Series Production/Series_Imagecard_02.jpg'
import imgSeries_Imagecard_03 from '../../../assets/Services/Production/Series Production/Series_Imagecard_03.jpg'
import { Container, Row, Col } from 'react-bootstrap';
import HFTab from '../../../Components/HfTabs/HfTabs';
import imgQuality from '../../../assets/Services/Production/Series Production/quality.jpg'
import imgInstallationKit from '../../../assets/Services/Production/Series Production/installationKits.jpg'
import imgEfficiency from '../../../assets/Services/Production/Series Production/efficiency.jpg'


const SeriesProduction = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;


    const Series_Imagetext_01 = {
        image: imgSeries_Imagetext_01,
        title: 'The highest product quality and process reliability',
        description: ['We reliably maintain a consistently high quality even with large production volumes. The reason for this is our working processes, which are designed to work efficiently with one another and are optimised through a continuous improvement process (CIP). We also operate a quality management system that meets the highest standards. Working in our cleanroom, we ensure the technical cleanliness of hose lines, pipelines and other hydraulic components, even in large batch series production. By exercising this level of extreme care, we prevent the finest particles from contaminating hydraulic systems.'],
    }

    const Series_Imagetext_02 = {
        image: imgSeries_Imagetext_02,
        title: 'State-of-the-art production machinery and equipment',
        description: ['Our practice proven manufacturing processes run on state-of-the-art machinery and guarantees consistently high quality. Our range of machinery includes automated cutting machines, semi-automatic cleaning systems, hydraulic hose crimping machines with crimping dimension monitoring, CNC bending machines, fully automatic bending robots and orbital welding equipment. With these resources, we can manufacture hose lines in nominal diameters of 3 to 50 mm and precision pipe in diameters of 4.00 to 101.60 mm. With the ability to produce special fittings in-house, we can manufacture hose lines and pipelines precisely to meet your requirements.'],
    }


    const Series_Imagetext_03 = {
        image: imgSeries_Imagetext_03,
        title: 'Logistics support',
        description: ['As a system partner, we believe series production does not start in the production hall. We support our customers from design and development right up to product dispatch.'],
        listItems: ["Advice and design for component and component assemblies", "Manufacture of kits, component assemblies and hydraulic power units", "Delivery to your main warehouse or installation location", "Replenishment of ordered components in the kanban system", "Digital EDI interface for rapid order processing"]
    }


    const Series_Imagetext_04 = {
        image: imgSeries_Imagetext_04,
        title: '',
        description: 'Our series production facilities are designed so that we can supply traditional, medium-sized companies as well as industrial corporations as an OEM with pipelines on hose lines, ready to install and including customer-specific special fittings. Precisely synchronised processes, experienced staff and reliable quality inspections have already impressed many companies at home and abroad, including renowned manufacturers of rail transport and municipal vehicles, construction and agricultural machinery. We work with our customers to drive forward innovation and in this way improve the quality of the end product and the manufacturing parameters.'

    }

    const featuresData = [
        {
            title: "Made-to-Measure Manufacturing",
            description: "We preassemble hose lines and pipelines individually to meet the customer’s requirements.",
            image: imgMark
        },
        {
            title: "High Process Reliability",
            description: "High process reliability allows us to achieve the proven HANSA‑FLEX quality in series production.",
            image: imgMark
        },
        {
            title: "Delivery on Schedule",
            description: "State-of-the-art manufacturing methods ensure rapid availability and delivery on time.",
            image: imgMark
        },
        {
            title: "Professional Advice",
            description: "We advise you extensively and implement the optimum solution in accordance with your requirements.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "High quality standards",
            description: "Our in-house quality assurance system ensures our processes are certified and maintain high quality standards. We can also perform customised inspections for you. TÜV-tested quality",
            listItems: ["Compliance with all relevant standards such as DIN EN 853, 854, 856, 857", "Precise particle analyses at a microscopic scale", "In-house impulse test benches", "Cleanroom for monitoring technical cleanliness", "Preparation of inspection and test reports in accordance with VDA Volume 19, ISO 16232"],
            image: imgQuality
        },
        {
            title: 'Prepacked installation kits',
            description: "All hose lines are supplied with fitting and sleeve and combined into a hose package. Your installation technicians can install the kit immediately without having to assemble the hose lines.",
            listItems: ["Ready to install installation kits and component assemblies", "Unambiguous packaging", "Delivery Just-In-Time directly to the production line", "Delivery Just-In-Sequence through our branches"],
            image: imgInstallationKit
        },
        {
            title: "Efficient order processing",
            description: "After receipt, orders are quickly checked and processed. We send you a cost estimate with a minimum purchasing quantity for the manufacture of your requested hose lines or pipelines.",
            listItems: ["Transparent cost breakdown", "Demand can be displayed in real-time via EDI interface if required", "Computer-controlled stock and batch management", "Continuous improvement (CIP) reduces costs and increases quality"],
            image: imgEfficiency
        },
    ];

    const tabTitles = [
        { title: "Quality", eventKey: "Quality" },
        { title: "Installation kits", eventKey: "Installation kits" },
        { title: "Efficiency", eventKey: "Efficiency" },
    ];



    return (
        <div>
            <CenterStage imageSrc={imgSeries_Centerspread} title={"SERIES PRODUCTION OF PIPELINES AND HOSE LINES"}
                paragraph={"Our strategically located branches in Johannesburg, Durban, Cape Town and Vereeniging deliver tens of thousands of articles including ready-to-install high-pressure, PTFE and industrial hose lines, made-to-measure and in small or large batches, to OEMs in South Africa and the rest of Africa every month. With us, you can expect excellent process reliability and compliance with quality standards. We also adapt our procedures in warehouse product management and logistics to suit your requirements."}
            />


            {/* <SectionHeader
                title={"PRODUCTION IN FULL FLOW"}
                subtitle={"Processes perfectly synchronised for the optimum solution."}
            />

            <div >
                <br />
                <ImageText data={Series_Imagetext_01} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Series_Imagetext_02} />
            </div>

            <div >
                <br />
                <ImageText data={Series_Imagetext_03} left={false} />
            </div> */}

            <SectionHeader
                title={"SERIES PRODUCTION BENEFITS"}
                subtitle={"From the production floor to the dispatch department, our series production team provides an all-round service perfectly based on your needs."}
            />

            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <SectionHeader
                title={"YOU CAN ALWAYS RELY ON US"}
                subtitle={"We supply you with high-quality hose lines and pipelines on schedule and in the quantities you wish."}
            />

            <div >
                <br />
                <ImageText data={Series_Imagetext_04} left={false} />
            </div>

            {/* TODO: Video_Videocenter*/}

            <SectionHeader
                title={"OUR SERVICES PORTFOLIO IN DETAIL"}
                subtitle={""}
            />
            <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />

            {/* TODO: Redbanner_Carrousel */}




            <SectionHeader
                title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                subtitle={""}
            />

            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgSeries_Imagecard_01} learnMoreLink="/Worksinworks" title="A suitable hose for every application" desc="With us you can select from a wide range of high-quality hydraulic, industrial, preformed hoses, pressure and suction hoses manufactured from many different materials." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgSeries_Imagecard_02} learnMoreLink="/Kitting" title="Hose fittings with zinc-nickel surface coating" desc="HANSA‑FLEX hose fittings are perfectly matched to the hose type, so that a safe connection is guaranteed even at high pressures." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgSeries_Imagecard_03} learnMoreLink="/Kanbandelivery" title="Pipelines made-to-measure" desc="Bent pipelines from HANSA‑FLEX are available in various materials, such as steel, stainless steel, copper or aluminium, and with different surface treatments." />
                    </Col>

                </Row>
            </Container>









        </div>
    )
}

export default SeriesProduction