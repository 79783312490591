import React, { useEffect } from 'react';
import CertificateCard from '../../Components/CertificateCard/CertificateCard';
import imgAbsCert1 from '../../assets/Certificates/ABS-Final Certificate WO 5477145-1.jpg';
import imgAOM from '../../assets/Certificates/Approved AOM-AMT00000SF 1-1.jpg';
import imgEn15085 from '../../assets/Certificates/Certificate-EN15085_ENG_HANSA_FLEX_INDIA_signed-1.jpg';
import imgISO3834 from '../../assets/Certificates/Certificate-ISO3834_ENG_HANSA_FLEX_INDIA_signed_SvR-1.jpg';
import imgBOM2200556 from '../../assets/Certificates/Inspection Certificate BOM2200556-SHI-01.jpg';
import imgISO9001 from '../../assets/Certificates/ISO-9001-2015-New Certificate-2022-2025 1.jpg';
import imgTFW from '../../assets/Certificates/TFW_LR_Type_Approval_TFW_LR2025131TA_CERT 1.jpg';
import imgTUV1 from '../../assets/Certificates/TUV Certificate -ISO14001 1.jpg';
import imgTUV2 from '../../assets/Certificates/TUV Certificate -ISO45001 1.jpg';
// import BBBEE from '../../assets/Certificates/BBBEE.jpg';
import BBBEE from '../../assets/Certificates/BEE CERT Hansa Flex exp 27 11 2024 (2)_page-0001.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook



function Certificates() {
    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 320, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 300, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div >
            <div data-aos={isMobile ? "fade-up" : "fade-left"}>
                <CertificateCard title={"ISO-9001-2015-New Certificate-2022-2025 1"} description={"Accreditation for ISO 9001:2015 standards, affirming commitment to quality management systems, enhancing organizational efficiency, customer satisfaction and global market competitiveness."} imageUrl={imgISO9001} />
            </div>
            {/* <CertificateCard title={"ABS-Final Certificate WO 5477145-1 "} description={"Official documentation confirming successful completion of Work Order 5477145-1, demonstrating adherence to ABS standards, ensuring quality, safety, and regulatory compliance in the specified project or task."} imageUrl={imgAbsCert1} />
            <CertificateCard title={"Approved AOM-AMT00000SF 1-1"} description={"Certification recognizing adherence to quality standards for specified processes, ensuring reliability and compliance within the industry, fostering trust and credibility."} imageUrl={imgAOM} />
            <CertificateCard title={"Certificate-EN15085_ENG_HANSA_FLEX_INDIA_signed-1 "} description={"Certificate-EN15085_ENG_HANSA_FLEX_INDIA: Recognizing compliance with EN 15085 standards, ensuring proficiency in welding of railway vehicles, upholding safety and quality benchmarks in rail transport systems."} imageUrl={imgEn15085} />
            <CertificateCard title={"Certificate-ISO3834_ENG_HANSA_FLEX_INDIA_signed_SvR-1 "} description={"Certificate-ISO3834_ENG_HANSA_FLEX_INDIA: Acknowledgement of ISO 3834 compliance, demonstrating excellence in welding quality management, ensuring consistent adherence to industry standards for superior welded products."} imageUrl={imgISO3834} /> */}
            {/* <CertificateCard title={"Inspection Certificate BOM2200556-SHI-01 "} description={"Inspection Certificate BOM2200556-SHI: Verification of inspection procedures and standards compliance, guaranteeing product integrity and reliability, enhancing customer confidence in quality assurance and regulatory adherence."} imageUrl={imgBOM2200556} /> */}
            <div data-aos={isMobile ? "fade-up" : "fade-right"} >
                <CertificateCard title={"Level 3 BBBEE supplier "} description={"A Level 3 BBBEE contributor with 30%+1 black ownership. "} imageUrl={BBBEE} />

            </div>
            {/* <CertificateCard title={"TFW_LR_Type_Approval_TFW_LR2025131TA_CERT 1"} description={"Type Approval Certification from LR, signifying compliance with technical and safety requirements, ensuring reliability and adherence to stringent marine industry standards."} imageUrl={imgTFW} />
            <CertificateCard title={"TUV Certificate -ISO14001 1"} description={"Recognition for ISO 14001 compliance, demonstrating commitment to environmental management, sustainability, and eco-friendly practices, contributing to a greener and more sustainable future."} imageUrl={imgTUV1} />
            <CertificateCard title={"TUV Certificate -ISO45001 1"} description={"TUV Certificate -ISO45001: Certification attesting to ISO 45001 compliance, ensuring effective occupational health and safety management systems, prioritizing employee welfare, and mitigating workplace risks for a safer work environment."} imageUrl={imgTUV2} /> */}

        </div>
    );
}

export default Certificates;