import React, { useEffect } from 'react'
import imgHFwholesaleseta from '../../assets/news-events/HFwholesaleseta.jpg'
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import TextCaption from '../../Components/TextCaption/TextCaption';
const Wholesale = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>
            <ImageDescription
                imageSrc={imgHFwholesaleseta}
                title={"HANSA-FLEX SOUTH AFRICA EMPOWERING YOUTH: OUR COMMITMENT TO SKILLS DEVELOPMENT RECOGNIZED BY WHOLESALE & RETAILS SETA"}
                paragraphs={[
                    "We are deeply honoured and grateful for the recognition by the Wholesale and Retail SETA for our contributions to people development, particularly in supporting unemployed youth through skills development initiatives.",
                    "Our commitment to developing both our employed staff and unemployed youth through skills development remains unwavering, as we uphold one of our core values of our mission statement: “Development.”"
                ]}


                alignment={'center'}
            />

            <TextCaption
                title={"Here are some notable achievements in collaboration with the Wholesale and Retail SETA since 2019: "}
                bulletPoints={[
                    "We have trained a total of 188 unemployed youth through learnerships, internships, and skills programs. ",
                    "We have trained 49 employed staff through skills programs, learnerships, and bursaries towards full qualifications.",
                    "We currently have 51 active unemployed learners for the current financial year. ",
                    "Impressively, 25% of our total workforce to date consists of youth who have joined our organization through the Wholesale and Retail SETA skills development initiatives. ",


                ]} />


            <ImageDescription
                // imageSrc={imgHFwholesaleseta}
                // title={"HANSA-FLEX SOUTH AFRICA EMPOWERING YOUTH: OUR COMMITMENT TO SKILLS DEVELOPMENT RECOGNIZED BY WHOLESALE & RETAILS SETA"}
                paragraphs={[
                    "This achievement aligns perfectly with our mission to continuously expand our professional expertise through development. Training and development are strategic initiatives and key elements of our business. We are dedicated to combating youth unemployment in South Africa and are thrilled to be making a positive impact. ",
                    "Thank you to WR&SETA and all our strategic partners for their continued support."
                ]}

                alignment={'center'}
            />

        </div>
    )
}

export default Wholesale