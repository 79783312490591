import React from "react";

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        right: "-50px",
        zIndex: 1,
        transform: "translateY(-50%)",
        cursor: "pointer",
        color: "red" // Set the color to red
      }}
      onClick={onClick}
    >
      {/* Your custom arrow icon for next */}
    </div>
  );
}

export default CustomNextArrow;
