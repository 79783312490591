import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import image1 from '../../assets/home2.jpg';
import stage1 from '../../assets/stage-1.jpg';
import './FullPageCarousel.css'; // Import CSS file
import { Link } from 'react-router-dom';
import SA_hero from "../../assets/SA_Hero3.jpg"


function FullPageCarousel() {

  const imageContainerStyle = {
    position: 'relative', // Make the container relative for absolute positioning of overlay
    maxHeight: '100%', // Set max height to auto
    maxWidth: '100%', // Set max width to auto
    overflow: 'hidden', // Hide overflow to crop image
    margin: '0 auto', // Center the container horizontally
    width: '100vw', // Set width to fill the viewport width
    display: 'flex', // Set display to flex
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center' // Center content vertically
  };

  const imageStyle = {
    width: '100%', // Set width to fill container
    height: 'auto', // Maintain aspect ratio
    objectFit: 'cover' // Crop the image to cover the entire container
  };

  const titleStyle = {
    marginBottom: '50px',
  };

  const descriptionStyle = {
    marginBottom: '30px', // Set bottom margin for description
    marginLeft: '20%', // Set left margin for description
    marginRight: '20%' // Set right margin for description
  };

  const buttonStyle = {
    backgroundColor: 'rgb(212,51,75)', // Button background color
    color: 'white', // Button text color
    padding: '10px 20px', // Button padding
    borderRadius: '5px', // Button border radius
    border: 'none', // Remove button border
    cursor: 'pointer', // Add cursor pointer on hover
    textDecoration: 'none', // Remove underline from button text
    width: '200px', // Adjust button width to fit content

  };

  const buttonWrapperStyle = {
    textAlign: 'center', // Center content horizontally within the wrapper
    marginBottom: '60px', // Set bottom margin for button wrapper

  };

  return (
    <Carousel>
      <Carousel.Item interval={2500} className='main-carousel-item'>
        <div style={imageContainerStyle} >
          <img className='main-carousel-image'
            src={SA_hero}
            alt="First slide"
            style={imageStyle}
          />
          <div className="image-overlay"> {/* Add the overlay */}
            <h3 className='c-slide-title' style={titleStyle}>HANSA-FLEX STANDS FOR HYDRAULICS </h3>
            <p className='c-slide-desc' style={descriptionStyle}> As a system partner HANSA-FLEX is an expert in the complex overall system of fluid technology and offers a full range of integrated services – from planning and consulting through engineering, unit engineering, the supply of fastening elements and assembly to commissioning and the training of operators</p>
            <div style={buttonWrapperStyle}> {/* Button wrapper */}
              <Link to="/hoses-and-hose-lines" style={buttonStyle}>Learn More</Link>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={2500} className='main-carousel-item'>
        <div style={imageContainerStyle} >
          <img className='main-carousel-image'
            src={image1}
            alt="Second slide"
            style={imageStyle}
          />
          <div className="image-overlay"> {/* Add the overlay */}
            <h3 className='c-slide-title' style={titleStyle}>EXPLORE OUR DISTINGUISHED PRODUCT LINE!</h3>
            <p className='c-slide-desc' style={descriptionStyle}>Discover hydraulic excellence with our vast product range! From innovative hose designs to essential connectors, each item in our product range embodies quality, durability, and functionality and is designed to elevate your fluid dynamics. Dive into the epitome of quality hydraulics today! </p>
            <div style={buttonWrapperStyle}> {/* Button wrapper */}
              <Link to="/hoses-and-hose-lines" style={buttonStyle}>Discover More</Link>
            </div>
          </div>
        </div>
      </Carousel.Item>

      {/*add more carousel items here*/}
    </Carousel>
  );
}

export default FullPageCarousel;
